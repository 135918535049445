<template>
  <b-row>
    <b-col md="3">
      <div>
        <b-img
          src="img/checkout/order-success.png"
          class="w-100 mb-3 border"
          alt=""
        ></b-img>
        <h6>HDFC Bank Offers</h6>
        <p>
          <small>
            Flat Rs 50 Off On Recharge/Bill Payment Of Min Rs 500 Using HSBC
            Cashback Credit Card Before 31 July</small
          >
        </p>
      </div>
    </b-col>
    <b-col md="3">
      <div>
        <b-img
          src="img/checkout/order-success.png"
          class="w-100 mb-3 border"
          alt=""
        ></b-img>
        <h6>HDFC Bank Offers</h6>
        <p>
          <small>
            Flat Rs 50 Off On Recharge/Bill Payment Of Min Rs 500 Using HSBC
            Cashback Credit Card Before 31 July</small
          >
        </p>
      </div>
    </b-col>
    <b-col md="3">
      <div>
        <b-img
          src="img/checkout/order-success.png"
          class="w-100 mb-3 border"
          alt=""
        ></b-img>
        <h6>HDFC Bank Offers</h6>
        <p>
          <small>
            Flat Rs 50 Off On Recharge/Bill Payment Of Min Rs 500 Using HSBC
            Cashback Credit Card Before 31 July</small
          >
        </p>
      </div>
    </b-col>
    <b-col md="3">
      <div>
        <b-img
          src="img/checkout/order-success.png"
          class="w-100 mb-3 border"
          alt=""
        ></b-img>
        <h6>HDFC Bank Offers</h6>
        <p>
          <small>
            Flat Rs 50 Off On Recharge/Bill Payment Of Min Rs 500 Using HSBC
            Cashback Credit Card Before 31 July</small
          >
        </p>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {};
</script>
<style scoped></style>
